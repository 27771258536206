@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;

}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  float: left;
  border-radius: 50%;
}

.biscImg {
  height: 15vmin;
}

.NavLinks {
  rotate: -30deg;
  font-size: medium;
}

@media (prefers-reduced-motion: no-preference) {

  .NavLinks:hover {
    font-size: Large;
  }


}

.App-header {

  background-color: #4f6899;
  min-height: 10vmin;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-Footer {
  margin: 0;
  padding: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #505866;
  color: white;
  text-align: center;
}

.BiscSplit {
  position: relative;
  height: 8.5vmin;
}

.biscPop {
  position: absolute;
  background-color: white;
  border-radius: 50px;
  height: 10vmin;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  vertical-align: middle;
  line-height: 10vmin;
  bottom: 8vmin;
  left: 12vmin;
}

.biscpoptriangle {
  position: absolute;
  line-height: 10vmin;
  border-left: 5vmin solid transparent;
  border-right: 5vmin solid transparent;
  border-bottom: 5vmin solid white;
  margin: 2rem;
  bottom: 2vmin;
  left: 8vmin;
  rotate: 190deg;
}

.TitleOfPage {
  margin-left: 10vw;
  min-height: 10vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xxx-large;
}


.App-link {
  color: #61dafb;
}

.footercoffee {
  margin-left: 5px
}




.RightColWork {

  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
  text-align: start;
  padding-left: 20px;


}


.workListInside {
  list-style: disc !important;
  list-style-position: inside;
  margin: auto;
  padding-left: 2vw;
}

.workList {
  list-style: disc !important;
  list-style-position: outside;
  margin: auto;
  line-height: 3vh;
  ;
}





/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App-logo {
    height: 20vmin;
    pointer-events: none;
    float: left;
    border-radius: 50%;
  }


  /**for work comp*/

  

  .SeaelHolder>.imgDiv {
   width: 22vw;
  }

.TimeLine{
  display: flex;
}
  .TimeLine > .smalldiv{

    display: inline-block;
    width:24vw;

  }




  .RightColWork {

    padding-top: 20px;
    padding-bottom: 20px;
    overflow: auto;
    text-align: start;
    padding-left: 20px;


  }


  .workListInside {
    list-style: disc !important;
    list-style-position: inside;
    margin: auto;

  }

  .workList {
    list-style: disc !important;
    list-style-position: outside;
    margin: auto;
    line-height: 3vh;
    
  }

  .workListInside {

    padding-left: 6vw;
  }


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 640px) {



  .SeaelHolder>.imgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
  }
  
  
  .TimeLine {
    height: 12vh;
    margin: 0;
    padding: 0;
  
  }
  
  
  .SeaelHolder {

    height: 28vh;
    width: 20vw;
  }
  

  .workListInside {

    padding-left: 4vw;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {




}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1024px) {

  .dotted-line {
    width: 6vw;
    height: 5vw;
    border-left: 3px dotted black;
    margin-left: 4vw;

  }


  .SeaelHolder{
    padding-top:1vh;
  }

  .workListInside { 
    padding-left: 2vw;
  }
  .RightColWork {
    
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: auto; 
    padding-left: 10vw;
  }

  .workSection{
    padding-top:4vh;
    display: flex;
  }


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1280px) {
  .pageContents {
    margin-left: 200px;
    margin-right: 200px;
  }

  .dotted-line {
    width: 6vw;
    height: 5vw;
    border-left: 3px dotted black;
    margin-left: 4vw;



  }
}